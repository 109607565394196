
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import { devicesModule } from '@/store/modules/store-accessor'
import riello_fault_mapping from "@/assets/fault_mapping/RIELLO.json";

@Component
export default class DevicesSideviewFaultsTab extends Vue {
 @Prop() device: Record<string, any>;
  
  currentLanguage = localStorage.getItem("lang") || "el";
  tableheaders: any [] = [
    {
      text: this.$t("date"),
      align: 'left',
      class: 'row-style',
      sortable: true,
      value: 'time'
    },
    { text: this.$t("fault"), sortable: true, class: 'row-style', value: 'oem' },
    { text: '', sortable: false, class: 'row-style', value: 'description' },
  ]

  deviceFaultData = []

  async mounted() {
      this.deviceFaultData = await devicesModule.getDeviceFaultHistory(this.device.info.device_id);
  }
  
  formatDate (date: string): string {
    return TimeUtil.formatInfluxDate(date)
  }

  get faultMap() {
    return riello_fault_mapping as any
  }

  //Format Error Codes here?
  faultMapping(code: string): any {
    if(this.device.info.device_type == 'DOMX_HC_MB') {
      // create the JSON for the Riello mapping and use it to translate. 
      return {"code": this.faultMap[code].code ?? code , "description": this.faultMap[code].description[this.currentLanguage] }
    }

    return {"code": code };
  }
};


import { Component, Vue, Prop } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import DashboardCardBarChart from "@/components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "@/components/Cards/DashboardCardPieChart.vue";
import DeviceOverviewCard from "@/components/Cards/DeviceOverviewCard.vue";
import DeviceOverviewSwitchCard from "@/components/Cards/DeviceOverviewSwitchCard.vue";
import DeviceOverviewButtonCard from "@/components/Cards/DeviceOverviewButtonCard.vue";
import DeviceOverviewHeatingBalanceSliderCard from "@/components/Cards/DeviceOverviewHeatingBalanceSliderCard.vue";
import DeviceOverviewSliderCard from "@/components/Cards/DeviceOverviewSliderCard.vue";
import DashboardCardFaultsPieChart from "@/components/Cards/DashboardCardFaultsPieChart.vue";
import DeviceOverviewRoomTargetCard from "@/components/Cards/DeviceOverviewRoomTargetCard.vue";
import DeviceOverviewRoomTargetLimitsCard from "@/components/Cards/DeviceOverviewRoomTargetLimitsCard.vue";
import DeviceOverviewSelectCard from "@/components/Cards/DeviceOverviewSelectCard.vue"

import { devicesModule } from '@/store/modules/store-accessor'

@Component({
  components: {
    DashboardCardBarChart,
    DashboardCardPieChart,
    DeviceOverviewCard,
    DeviceOverviewSwitchCard,
    DeviceOverviewButtonCard,
    DeviceOverviewHeatingBalanceSliderCard,
    DeviceOverviewSliderCard,
    DeviceOverviewRoomTargetCard,
    DeviceOverviewRoomTargetLimitsCard,
    DashboardCardFaultsPieChart,
    DeviceOverviewSelectCard
  }
})

export default class MBControlView
 extends Vue {
  @Prop() device: Record<string, any>;

  expansionPanel = [0];
  terminalUnitList = [
  {"name": "FCU", "value": 0},
  {"name": "RAD", "value": 1},
  {"name": "FLH", "value": 2},
  ];

  get deviceData () {
    return this.device.data.properties
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  heatingControlChange(val: string) : void {
    let data = {
        action: "heat_set",
        action_value: val ? 1 : 0,
    }
    devicesModule.updateDeviceConfig(data);
  }

  fixedBoilerTemperatureChange(val: string) : void {
    let data = {
        action: "t_set",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  defaultAction(val: string, action: string) : void {
    let data = {
        action: action,
        action_value: val,
    }

    devicesModule.updateDeviceConfig(data);

  }

};

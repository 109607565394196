<!--
 Copyright 2022 by domX Private Company

 All rights reserved,
 also regarding any disposal, exploitation, reproduction,
 editing, distribution, as well as in the event of
 applications for industrial property rights.

 This software contains proprietary and confidential
 information of domX Private Company.
 The disclosure of confidential information without
 express authorization is prohibited.

 This software may include Open Source Software.
 As far as Open Source Software is included,
 your rights for the respective Open Source Software
 components are governed by the applicable
 Open Source Software licenses.
-->

<template class="fill-height">
  <v-card
      flat
      class="secondary fill-height pa-12"
      height="25%">
    <v-form
      ref="form"
      lazy-validation
    >
      <v-list class="secondary" max-width="1000px">
        <v-subheader class="display-1 grayBold--text">
          {{$t("add_device")}}
        </v-subheader>
        <v-list-item>
          <v-list-item-content class="grayBold--text">
            <v-list-item-title class="subtitle-2 grayBold--text">

            </v-list-item-title>
              <v-row no-gutters xs12 sm6 md6>
                <v-flex hidden>
                  <v-text-field disabled class="mr-5" min="1" type="number" required :rules="tagRule" light v-model="installationModel.tag" :label="$t('tag')" v-on:keypress="isNumber"/>
                </v-flex>
                <v-col cols="6">
                  <v-text-field @input="checkDeviceId" :error-messages="deviceIdRuleMessages" :rules="deviceIdRules" class="mr-5 textfield" light v-model="installationModel.device_id" append-icon="mdi-cellphone-cog" :label="$t('device_id')" />
                </v-col>
                <v-col cols="6">
                  <v-text-field @input="checkDeviceId" :error-messages="deviceIdRuleMessages" :rules="serialNoRules" class="mr-5 textfield" light v-model="installationModel.serial_no" append-icon="mdi-numeric" :label="$t('serial_number')" />
                </v-col>
              </v-row>
              <v-row no-gutters xs12 sm6 md6>
                <v-col cols="6">
                  <v-autocomplete
                        light
                        class="grayBold--text mr-5 textfield"
                        v-model="installationModel.client"
                        :items="clientsCombo"
                        item-text="name"
                        :label="$t('client')"
                        return-object
                        required 
                        :rules="clientRule"
                        @input="getClientInstallations"
                      ></v-autocomplete>
                  </v-col>
                  <v-text-field class="mr-5 textfield" light required :rules="deviceNameRules" v-model="installationModel.device_name" :label="$t('device_friendly_name')" />
              </v-row>
              <v-row no-gutters xs12 sm6 md6>
                <v-col cols="6">
                <v-autocomplete
                  v-if="!newInstallations"
                  light
                  class="grayBold--text mr-5 textfield"
                  v-model="installationModel.installation_id"
                  :items="clientInstallations"
                  item-text="address"
                  :label="$t('installation')"
                  return-object
                  required 
                  :disabled="installationModel.client.id == 0"
                  :rules="installationRule"
                ></v-autocomplete>
                <v-checkbox
                      light
                      v-model="newInstallationFlag"
                      class="grayBold--text textfield"
                      color="red"
                      :label="$t('new_installation')"
                      @change="checkboxChanged"/>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-row v-if="newInstallations" no-gutters xs12 sm6 md6>
                  <v-row no-gutters xs12 sm6 md6>
                      <vuetify-google-autocomplete
                      v-if="newInstallations"
                      light
                      v-model="installationModel.address"
                      ref="google_address"
                      :label="$t('address')"
                      :placeholder="'_'"
                      id="map"
                      class="grayBold--text mr-5 textfield"
                      v-on:placechanged="getAddressData"
                      types=""
                      :rules="addressRule"
                    />
                    <v-text-field v-if="newInstallations" class="mr-5 textfield" light required :rules="areaRule" v-model="installationModel.area" :label="$t('area')" />
                  </v-row>
                  <v-row no-gutters xs12 sm6 md6>
                    <v-text-field class="mr-5 textfield" light v-model="installationModel.zip_code" :rules="zipCodeRule" :label="$t('zip_code')" />
                  </v-row>
                  <v-row  no-gutters xs12 sm6 md6>
                    <v-text-field class="mr-5 textfield" min="-9" max="20" type="number" light v-model="installationModel.floor" :label="$t('floor')" v-on:keypress="isNumber"/>
                  </v-row>
                </v-row>
              </v-expand-transition>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          depressed
          right
          light
          color="grayBold--text secondary"
          @click="hideModal()"
        >
          {{$t("cancel")}} 
        </v-btn>
        <v-btn
          color="primary"
          depressed
          right
          large
          :class="{'disable-events': !deviceIdValid}"
          @click="doAddInstallation"><v-icon left>mdi-plus</v-icon>{{$t("add")}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, devicesModule, installationsModule, clientsModule } from '@/store/modules/store-accessor'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';


@Component({
  components: {
    VuetifyGoogleAutocomplete
  }
})
export default class AddInstallationModal extends Vue {
  @Prop()
  payload


  installationModel = {
   tag: this.installationNextTagId,
   client: {name: "", id: 0},
   owner: {name: "", id: 0},
   installation_id: null,
   address: "",
   area: "",
   zip_code: "",
   floor: "",
   is_premium: false,
   notes: "",
   latitude: "",
   longitude: "",
   device_id: "",
   device_name: "",
   serial_no: "",
  }

  google_address

  newInstallationFlag = false;

  deviceIdRules = [
    (val) => !!val || this.$i18n.t("deviceid_required"),
  ]

  deviceNameRules = [
    (val) => val.length <= 100 || this.$i18n.t("device_name_length_rule"),
  ]

  serialNoRules = [
    (val) => !!val || this.$i18n.t("serialno_required"),
  ]

  tagRule= [
    (val) => !!val || this.$i18n.t("tag_required"),
  ]
  clientRule= [
    (val) => ((!!val || val == '') && val.name.length > 0) || this.$i18n.t("client_required"),
  ]

  installationRule = [
    (val) => ((!!val || val == '') && val.address.length > 0) || this.$i18n.t("installation_required"),
  ]

  areaRule= [
    (val) => !!val || this.$i18n.t("area_required"),
    (val) => val.length <= 30 || this.$i18n.t("form_area_length_rule"),
  ]

  addressRule= [
    (val) => val.length <= 100 || this.$i18n.t("form_address_length_rule"),
  ]

  zipCodeRule= [
    (val) => val.length <= 15 || this.$i18n.t("form_zipCode_length_rule"),
  ]

  notesRule= [
    (val) => val.length <= 1000 || this.$i18n.t("form_notes_length_rule"),
  ]

  get clientsCombo () {
      return commonModule.clientsCombo;
  }

  get installationNextTagId () {
      return installationsModule.installationNextTagId;
  }

  get deviceIdValid() {
    return devicesModule.isValidDeviceId;
  }

  get clientInstallations() {
    return clientsModule.clientInstallations;
  }

  get newInstallations() {
    return this.newInstallationFlag;
  }

  get deviceIdRuleMessages() {
    if(this.installationModel.device_id.length > 0) {
      return (this.deviceIdValid)  ? [] : this.$i18n.t('deviceid_invalid')
    }
    else {
      return [];
    }
  }

  async getClientInstallations() {
    await clientsModule.getClientInstallations(this.installationModel.client.id)
  }

  async checkDeviceId() {
    await devicesModule.checkDeviceId({"device_id" : this.installationModel.device_id , "serial_no": this.installationModel.serial_no})
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  doAddInstallation() {
    if (this.$refs.form.validate()) {
      devicesModule.addDevice(this.installationModel);
      this.hideModal();
      // devicesModule.getDevices(); //DEV-2322
      devicesModule.getDeviceCount();
      devicesModule.getDevices(devicesModule.filters);
    }
  }

  checkboxChanged(val) {
    this.installationModel.installation_id = null;
  }

  getAddressData(addressData) {
        this.google_address = addressData;
        this.installationModel.area = addressData.locality;
        this.installationModel.zip_code = addressData.postal_code.replace(/\s/g, "");
        this.installationModel.latitude = addressData.latitude;
        this.installationModel.longitude = addressData.longitude;
        this.installationModel.installation_id = null;
  }

  isNumber(evt) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    }
};
</script>

<style scoped lang="scss">
.disable-events {
  pointer-events: none;
  opacity: 0.6;
}


.textfield {
  min-width: 400px;
}

#map::placeholder{
  color: #00000000 !important;
}
</style>

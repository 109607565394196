import { render, staticRenderFns } from "./MBGeneralView.vue?vue&type=template&id=2c3f1273&scoped=true&class=fill-height"
import script from "./MBGeneralView.vue?vue&type=script&lang=ts"
export * from "./MBGeneralView.vue?vue&type=script&lang=ts"
import style0 from "./MBGeneralView.vue?vue&type=style&index=0&id=2c3f1273&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c3f1273",
  null
  
)

export default component.exports
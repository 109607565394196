import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import { TimeUtil } from '@/helpers/TimeUtil'
import { companyModule } from '@/store/modules/store-accessor' 
import DevicesApi from '@/api/devices-api';
import _ from 'lodash';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { Parser } from 'json2csv';
import { convertJSONToCSV } from "@/helpers/data";

@Module({ namespaced: true, name: "devices" })
export default class DevicesModule extends VuexModule {
  public devices: DeviceDto[] = [];
  public devicesConsumptionData: any = []
  public devicesClimateData: any = []
  
  public devicesClimateMeanData: any = {}
  public devicesConsumptionTotalData: any = {}

  public devicesSavingsData: any = []
  public devicesSavingsKPI: any = {}
  
  public deviceActiveFaults: any = [];
  public deviceActiveFaultsChart = [0, 0];

  public queryObject: any = {};
  public filters = "";

  public installationsTable: InstallationTable[] = [];
  public currentDevice: any =
    { data: {}, info: {} } as any;

  public currDeviceData: any = {}

  public deviceCount = 0;
  public devicesOnlineCount = 0;
  public devicesFaultCount = 0;

  public isValidDeviceId = false;

  public isLoadingSideView = true;
  public isLoadingTable = true;

  @Action({ commit: "setDevices" })
  async getDevices(query: string): Promise<DeviceDto[]> {
    this.setIsLoadingTable(true);
    const devicesApi = new DevicesApi();
    const devices = await devicesApi.getAllDevices(query);
    this.setIsLoadingTable(false);
    return devices;
  }

  @Action({ commit: "setDeviceActiveFaults" })
  async getDevicesActiveFaults(): Promise<any[]> {
    const devicesApi = new DevicesApi();
    const devices = await devicesApi.getAllDevicesFaultHistory();

    return devices;
  }

  @Action({ commit: "setDeviceCount" })
  async getDeviceCount(): Promise<CountDto> {

    const devicesApi = new DevicesApi();
    const devicesCount = await devicesApi.getAllDevicesCount();
    return devicesCount;
  }

  @Action
  async deleteDevice(data: Record<string, any>) {

    const devicesApi = new DevicesApi();
    try {

      await devicesApi.deleteDevice(data.device_id)
      await this.getDevices('');
    }
    catch (error) {
      return 0;
    }
  }

  @Action({ commit: "setValidDeviceId" })
  async checkDeviceId(data: any) {
    const devicesApi = new DevicesApi();
    const response: any = await devicesApi.checkDeviceId(data);
    return response.exists
  }

  
  @Action
  async editDevice(data: Record<string, any>) {
    const dataBody = _.cloneDeep(data)
    const devicesApi = new DevicesApi();
    await devicesApi.updateDeviceInfo(dataBody.device_id, dataBody)
  }

  @Action
  async updateDeviceConfig(data: any) {
    const devicesApi = new DevicesApi();
    try {

      await devicesApi.updateDeviceConfig(this.currentDevice.data.deviceId, data)
      await this.getDeviceInfo(this.currentDevice.data.deviceId);
    }
    catch (error) {
      return 0;
    }
  }

  @Action
  async getDeviceMetrics(deviceId: string): Promise<Record<string, any>> {
    const devicesApi = new DevicesApi();
    const devicesCount = await devicesApi.getDeviceDataMetrics(deviceId);
    return devicesCount;
  }

  @Action({rawError: true})
  async exportDeviceData(data: any) {
    const devicesApi = new DevicesApi();
    const exportData = await devicesApi.getDeviceDataExportGrouped(data.deviceId, data.exportModel);

    const exportFileName = `${data.deviceId}-${data.exportModel.time_from}_${data.exportModel.time_to}_export` ;
    if(data.exportModel.file_type === 'JSON') {
      const JSONblob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
      const file = new File([JSONblob], `${exportFileName}.json`);
      FileSaver.saveAs(file);
    }
  }

  @Action({ commit: "setCurrentDevice" })
  async getDeviceInfo(
    currDeviceId: string
  ): Promise<any> {

    const devicesApi = new DevicesApi();
    const deviceDetails = { data: {} as any, info: {}}; 
    
    // Enable this only when MongoWith Data works
    // deviceDetails.data = await devicesApi.getDevice(currDeviceId);
    const startOfDay = dayjs().startOf('day').valueOf() * 1000000//ValueOf gives time in Milliseconds time in browser local time
    const endOfDay = dayjs().endOf('day').valueOf() * 1000000//ValueOf gives time in Milliseconds time in browser local time
    
    try {
      deviceDetails.data = await devicesApi.getDeviceInflux(currDeviceId, startOfDay, endOfDay);
      deviceDetails.info = await devicesApi.getDeviceInfo(currDeviceId);

    }
    catch(e) {
      console.log('Error', e)
    }

    return deviceDetails;
  }

  @Action
  async getDeviceFaultHistory(currDeviceId: string) {
    const devicesApi = new DevicesApi();

    //Testing Faults

    //Use days for this...

    const date_to = dayjs().valueOf() * 1000000//ValueOf gives time in Milliseconds time in browser local time
    const date_from = dayjs().subtract(30, 'days').valueOf() * 1000000//ValueOf gives time in Milliseconds time in browser local time

    const deviceFaults = await devicesApi.getDeviceFaultHistory(currDeviceId, date_from, date_to);
    // end - Testing Faults
    return deviceFaults;
  }

  @Action({ commit: "setCurrentDeviceData" })
  async getDeviceData(data: any) {
    const devicesApi = new DevicesApi();
    const deviceData = await devicesApi.getDeviceData(data.deviceId, data.days, data.time_from, data.time_to, data.metric);
    return deviceData;
  }

  @Action({ commit: "setCurrentDeviceMetadata" })
  async getDeviceMetadata(data: any) {
    const devicesApi = new DevicesApi();
    const deviceData = await devicesApi.getDeviceMetadata(data.deviceId, data.days, data.time_from, data.time_to, data.metric);
    return deviceData;
  }

  @Action({ commit: "setDeviceConsumptionTotal" })
  async getDeviceConsumptionDataSeries(data: any) {
    const devicesApi = new DevicesApi();

    //Create hour or days here
    const query =`date_from=${data.date_from}&date_to=${data.date_to}`;
    const deviceData = await devicesApi.getDeviceDataConsumptionHistory(query);
    return deviceData;
  }

  @Action({ commit: "setDeviceClimateTotal" })
  async getDeviceClimateDataSeries(data: any) {
    const devicesApi = new DevicesApi();

    //Create hour or days here
    const query =`date_from=${data.date_from}&date_to=${data.date_to}`;
    const deviceData = await devicesApi.getDeviceDataClimateHistory(query);
    return deviceData;
  }

  @Action({ commit: "setDeviceSavingsData" })
  async getDeviceSavingsData(data: any) {
    const devicesApi = new DevicesApi();

    //Create hour or days here
    const query = data.hours ? `hours=${data.hours}` : `days=${data.days}`;
    const deviceData = await devicesApi.getDeviceDataSavingsHistory(query);
    return deviceData;
  }

  @Action({ commit: "setDeviceSavingsKPI" })
  async getDeviceSavingsKPI(data: any) {
    const devicesApi = new DevicesApi();

    //Create hour or days here
    const query = data.hours ? `hours=${data.hours}` : `days=${data.days}`;
    const deviceData = await devicesApi.getDeviceDataSavingsKPI(query);
    return deviceData;
  }

  @Action({ commit: "setDeviceTotalConsumption" })
  async getDeviceTotalConsumption(data: any) {
    const devicesApi = new DevicesApi();

    //Create hour or days here
    const query =`date_from=${data.date_from}&date_to=${data.date_to}`;
    const deviceData = await devicesApi.getDeviceDataConsumptionTotal(query);
    return deviceData;
  }

  @Action({ commit: "setDeviceMeanClimate" })
  async getDeviceMeanClimate(data: any) {
    const devicesApi = new DevicesApi();

    //Create hour or days here
    const query =`date_from=${data.date_from}&date_to=${data.date_to}`;
    const deviceData = await devicesApi.getDeviceDataClimateMean(query);
    return deviceData;
  }

  @Action
  async addDevice(data: Record<string, any>) {

    data.client_id = data.client.id;
    delete data.client

    if (data.address == null || data.address == '') {
      delete data.address
    }
    else {
      data.address = data.address.split(",")[0];
    }
    if (data.zip_code == "") {
      delete data.zip_code
    }
    if (data.floor == "") {
      delete data.floor
    }
    if (data.latitude == "") {
      delete data.latitude
    }
    if (data.longitude == "") {
      delete data.longitude
    }
    if (data.notes == "") {
      delete data.notes
    }
    if (data.owner.name == "") {
      delete data.owner
    }
    else {
      data.owner_id = data.owner.id;
    }

    data.tag_id = companyModule.company.key + "_" + data.tag;
    delete data.tag

    const devicesApi = new DevicesApi();
    const response = await devicesApi.createDevice(data)
    await this.getDevices('');
    return response;
  }

  @Mutation
  public setDevices (devices: DeviceDto[]): void {
    try {
      devices.map(item => {
        item.client_name = `${item.client_firstname} ${item.client_lastname}`
        item.tag_split = item.tag_id.split('_').pop();
        item.readable_created_at = TimeUtil.formatDateTime(item.created_at)
        item.readable_installation_date = TimeUtil.formatDateTime(item.installation_date)
        item.readable_updated_at = TimeUtil.formatDateTime(item.updated_at)
      })

      this.devicesOnlineCount = (devices.filter((item) => item.onlineState)).length;

      this.devices = devices
      this.isLoadingTable = false;
    } catch(error) {
      this.isLoadingTable = false;
    }
  }

  @Mutation
  public clearDevices (): void {
    this.devices = [];
  }

  @Mutation
  public setDeviceActiveFaults (devicesWithFaults: any[]) :void {
    devicesWithFaults.map(item => {
      item.readable_date =  TimeUtil.formatDateTime(item?.time)
    })

    this.devicesFaultCount = devicesWithFaults.length;

    this.deviceActiveFaultsChart[0] = devicesWithFaults.filter((x) => x.blocking == 0).length;
    this.deviceActiveFaultsChart[1] = devicesWithFaults.filter((x) => x.blocking == 1).length;

    this.deviceActiveFaults = devicesWithFaults;
  }

  @Mutation
  public setDeviceCount(deviceCount: CountDto): void {
    this.deviceCount = deviceCount.count;
  }

  @Mutation
  public setCurrentDevice(deviceInfo: any): void {
    this.currentDevice = deviceInfo;
    this.isLoadingSideView = false;
  }

  @Mutation
  public setCurrentDeviceData(deviceData: any): void {
    this.currDeviceData = { ...this.currDeviceData, ...deviceData};
  }

  @Mutation
  public setCurrentDeviceMetadata(deviceMetadata: any): void {
    this.currDeviceData = { ...this.currDeviceData, ...deviceMetadata};
  }

  @Mutation
  public setDeviceConsumptionTotal(deviceData: any): void {
    this.devicesConsumptionData = deviceData;
  }


  @Mutation
  public setDeviceClimateTotal(deviceData: any): void {
    this.devicesClimateData = deviceData;
  }

  @Mutation
  public setDeviceSavingsData(deviceData: any): void {
    this.devicesSavingsData = deviceData;
  }

  @Mutation
  public setDeviceSavingsKPI(deviceData: any): void {
    this.devicesSavingsKPI = deviceData;
  }

  @Mutation
  public setDeviceTotalConsumption(deviceData: any): void {
    this.devicesConsumptionTotalData = deviceData;
  }

  @Mutation
  public setDeviceMeanClimate(deviceData: any): void {
    this.devicesClimateMeanData = deviceData;
  }
  
  @Mutation
  public unsetCurrentDevice(): void {
    this.currentDevice = { data: {}, info: {}} as any;
    this.currDeviceData = {} as any;
  }

  @Mutation
  public setIsLoadingSideView(isLoadingSideView: boolean): void {
    this.isLoadingSideView = isLoadingSideView
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }

  @Mutation
  public setValidDeviceId(isValid: boolean): void {
    this.isValidDeviceId = isValid;
  }

  @Mutation
  public setFilters(filters: string): void {
    this.filters = filters
  }

  @Mutation
  public setQueryObject(queryObject: any): void {
    this.queryObject = queryObject
  }
}

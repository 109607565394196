
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DeviceOverviewSelectCard extends Vue {
  @Prop() titleNumber: any;
  @Prop() subTitle: any;
  @Prop() cols: any;
  @Prop() textFontSize: any;
  @Prop() control: any;
  @Prop() controlVariable: any;
  @Prop() itemList: any;
  @Prop() itemText: any;
  @Prop() itemValue: any;
  @Prop() value: any;

  currentValue: any;

  mounted() {
    this.currentValue = this.value;
  }

  get itemSelected() {
    console.log('This value', this.value);
    console.log('This currentValue', this.currentValue);
    return this.itemList.find((obj: any) => obj.value === this.value);
  }

  set itemSelected(selectedValue: any) {
    this.currentValue = selectedValue;
  }

  selectStateChange() {
    this.$emit("defaultAction", this.currentValue[this.itemValue], this.controlVariable);
  }
}
